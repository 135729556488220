import React from "react";
import styles from "./index.module.scss";
import Button from "../Button";


class WeAreCTA extends React.Component {
render() {
    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                        <div className="work-content text-center">
                            <h2 className={styles.h2}>We are Shift Connect. </h2>
                            <p className=" my-3 mt-sm-4 mb-sm-5">We are a team of foreign exchange and global payments experts with decades of industry experience. Headquartered in Calgary, Alberta, Canada we have team members around the globe. We serve both corporate and private clients, empowering them to exchange funds in 130 currencies, hold funds on account in 60 of those currencies, and make local payments without sending wires in 30 countries including UK, Europe, US, and Canada. </p>
                            <Button
									to= "/about"
									className={styles.button}
								>
									About Us
					        </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
}

export default WeAreCTA;