import React from "react";
import styles from "./index.module.scss"
import {Col, Row} from "react-bootstrap";


class Index extends React.Component {
    render() {
        return <div className={styles.container}>
            <Row class="row full-width-row">
                <Col md={6} className={styles.leftColumn}/>
                <Col md={6} className={"d-flex flex-column justify-content-center"}>
                    <h2>What We Do.</h2>
                    <h2>Global Money Transfers </h2>
                    <p>Our global payments platform allows you to send money quickly and securely in just a few
                        quick clicks. You can view currency conversions in real-time, send and receive payments,
                        and take advantage of our competitive exchange rates every step of the way. Our platform
                        enables you to send money from the comfort of your own home or office without worrying
                        about hidden fees, extended wait times, or misinformation.
                    </p>
                    <h2>International Payment Processing</h2>
                    <p>With our connective platform, you gain immediate access to the global marketplace. Our
                        leading technology gives you the freedom to process payments, make FX transfers, and
                        book trades – securely, on your own, and in real-time. We are passionate about breaking
                        down the borders surrounding international payments so you can confidently send money
                        abroad without worrying about complex logistics or added costs.
                    </p>
                </Col>
            </Row>
            <Row class="row full-width-row">
                <Col md={6} className={"d-flex flex-column justify-content-center"}>
                    <h2>Hedging and Treasury </h2>
                    <p>Explore the benefits of hedging and treasury services. Identify potential risk exposures,
                        implement hedging techniques, and adjust as needed. Our services are designed to enhance
                        stability, optimize liquidity management, and remain agile within the ever-changing landscape of
                        global markets.

                    </p>

                    <h2>Risk Management</h2>
                    <p>Our cutting-edge platform empowers you to proactively mitigate risk in the dynamic world of
                        foreign exchange. The Shift experience allows you to navigate global markets with confidence.
                        When you partner with Shift, our innovative platform is yours to leverage.
                    </p>
                </Col>
                <Col md={6} className={styles.leftColumn2}/>
            </Row>
            <Row class="row full-width-row">
                <Col md={6} className={styles.leftColumn3}/>
                <Col md={6} className={"d-flex flex-column justify-content-center"}>
                    <h2>Multi-Currency Accounts </h2>
                    <p>Our corporate and private clients can utilize our secure multi-currency accounts to hold and
                        transact in multiple currencies, all in one place. This service allows you to save and pay in
                        several different currencies without the added stress of opening multiple international bank
                        accounts. While simplifying your payment process, it also streamlines your record-keeping and
                        accounting procedures in an efficient and centralized manner.
                    </p>
                    <h2>Customer Service</h2>
                    <p>Above all else, we're a team of experts who are passionate about serving and supporting you as
                        you enter the foreign exchange and global payments market. Our customer service relationship
                        continues once you are onboarded. We're always available to assist you with any questions or
                        ideas you may have every step of the way. From adjusting your service packages to helping you
                        navigate a new type of transaction, we are here for you. Our team is your dedicated resource.
                    </p>
                </Col>
            </Row>
        </div>;
    }
}

export default Index;