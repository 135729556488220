import React from "react";
import {Field, Formik} from "formik";
import * as yup from "yup";
import styles from "./index.module.scss";
import {Col} from "react-bootstrap";
import {navigate} from "gatsby-plugin-intl";

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const validationSchema = yup.object().shape({
    name: yup.string()
        .required("Name is required"),
    email: yup
        .string()
        .email("Invalid Email Address")
        .required("Email is required"),

    telephone: yup.string(),
})

const GatedContent2 = () => {
    const onSubmit2 = (data) => {
        fetch("/", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: encode({
                "form-name": "white-paper3",
                ...data,
            }),
        })
            .then(() =>
                navigate("/download-complete")
            )
            .catch((error) => console.log('error', error));
    };
    return (
        <Col xs={12} lg={6} className={styles.gatedContentWrapper}>
            <div className={styles.sendMoney}>
                <h2>FREE White Paper: Your Guide to Managing Forex Risk
                    as Your Business Grows Global</h2>
                <Formik
                    initialValues={
                        {
                            name: "",
                            email: "",
                            telephone: "",
                            question: "",
                        }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit2}>
                    {({

                          handleChange,
                          handleSubmit,
                          handleBlur,
                          isSubmitting,
                          values,
                          touched,
                          isValid,
                          errors,
                          dirty,
                      }) => (
                        <form
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            name="white-paper3"
                            method="post"
                            onSubmit={onSubmit2}
                            action={"/download-complete"}
                        >
                            <input type="hidden" name="bot-field" onChange={handleChange}/>
                            <input type="hidden" name="form-name" value="white-paper3"/>
                            <div>
                                <label htmlFor="name">Name</label>
                                <Field
                                    type="text"
                                    name="name"
                                    className={`${styles.contactFormInput} ${
                                        touched.name && errors.name ? styles.errorInput : ""
                                    }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                {errors.name && touched.name ? (
                                    <div className={styles.error}>{errors.name}</div>
                                ) : null}
                            </div>
                            <div>
                                <label htmlFor="email">Email</label>
                                <Field
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    className={`${styles.contactFormInput} ${
                                        touched.email && errors.email ? styles.errorInput : ""
                                    }`}/>
                                {errors.email && touched.email ? (
                                    <div className={styles.error}>{errors.email}</div>
                                ) : null}
                            </div>
                            <div>
                                <label htmlFor="phone">Phone</label>
                                <Field
                                    type="text"
                                    name="phone"
                                    className={styles.contactFormInput}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                />
                            </div>
                            <button
                                type="submit"
                                className={styles.contactFormSubmitButton}
                                disabled={!(dirty && isValid) || isSubmitting}
                            >
                                {isSubmitting ? "Please wait..." : "Download"}
                            </button>
                        </form>)}
                </Formik>
            </div>
        </Col>
    )
}

export default GatedContent2;
