import React from "react";
import classNames from "classnames/bind";
import { Row, Col } from "react-bootstrap";
import { injectIntl } from 'gatsby-plugin-intl';

import Section from "../Section";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class Statistics extends React.Component {
	render() {
		const intl = this.props.intl;
		let statisticsSectionClasses = classNamesBound({
			statisticsSection: true,
			[`${this.props.themeType}`]: this.props.themeType
		});

		return (
			<Section className={statisticsSectionClasses}>
				<Row className={styles.keyPoints}>
					<Col xs={12} md={4} className={styles.keyPoint}>
						{intl.formatMessage({id: 'statistics.first'})}
					</Col>
					<Col xs={12} md={4} className={styles.keyPoint}>
						{intl.formatMessage({id: 'statistics.second'})}
					</Col>
					<Col xs={12} md={4} className={styles.keyPoint}>
						{intl.formatMessage({id: 'statistics.third'})}
					</Col>
				</Row>
			</Section>
		);
	}
}

export default injectIntl(Statistics);
